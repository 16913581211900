@import './../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.hero {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 1800px;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;

  @keyframes movingGradient {
    0% {
      background-size: 100%;
      background-position: left center;
      background-repeat: no-repeat;
    }

    33% {
      background-size: 200%;
      background-position: left bottom;
      background-repeat: no-repeat;
    }

    50% {
      background-size: 150%;
      background-position: center center;
      background-repeat: no-repeat;
    }

    66% {
      background-size: 200%;
      background-position: right top;
      background-repeat: no-repeat;
    }

    100% {
      background-size: 100%;
      background-position: left top;
      background-repeat: no-repeat;
    }
  }

  &_ctn {
    position: relative;
    padding: $space-xs 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $newblue;
    background-repeat: no-repeat;
    animation: movingGradient 30s ease infinite;

    > div {
      padding: 0 15px;
      min-width: 680px;
      width: 75%;
      color: white;

      .breadcrumb {
        margin-bottom: $space-md;
        text-transform: uppercase;

        span,
        a {
          color: $grey-blue;
        }

        a {
          &:hover {
            color: white;
          }
        }
      }

      p {
        font-size: 1.2rem;
      }

      .buttonsEdited {
        font-size: 0.9rem;
        font-weight: 500;

        .btn {
          text-transform: uppercase;

          &--default {
            color: currentColor;
            border: 1px solid currentColor;
            opacity: 0.75;
            transition: opacity 0.2s ease-in;

            &:hover {
              color: currentColor;
              background-color: transparent;
              opacity: 1;
              transition: opacity 0.3s ease;
            }

            &.phone {
              opacity: 1;
              transition: color 0.2s ease-in, border-color 0.2s ease-in;

              &:hover {
                color: $secondary;
                border-color: $secondary;
              }
            }
          }
        }

        button {
          cursor: auto;
        }
      }
    }
  }

  &_media {
    position: relative;

    &_image {
      width: 100%;
      height: 100%;
      max-height: 90vh;
      border-radius: $border-radius;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_slider {
      width: 100%;
      height: 100%;
      //border-bottom: 4px solid $secondary;

      .awssld__wrapper {
        border-radius: 5px;
      }
    }

    &_career {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      z-index: 9;

      img {
        height: 10rem;

        @include media-breakpoint-up(lg) {
          height: 8rem;
        }

        @include media-breakpoint-up(xl) {
          height: 10rem;
        }

        &:first-child {
          margin-bottom: $space-xs;
        }
      }
    }
  }

  @keyframes jump {
    0% {
      transform: scaleX(1) translate(-50%, 0);
    }

    45% {
      transform: scaleX(0.9) translate(-50%, 20px);
    }

    55% {
      transform: scaleX(0.9) translate(-50%, 20px);
    }

    100% {
      transform: scaleX(1) translate(-50%, 0);
    }
  }

  &_scroll {
    z-index: 10;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    animation: jump 5s ease infinite;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:hover {
      span {
        border-color: $secondary;

        &::after {
          border-color: $secondary;
        }
      }
    }

    span {
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid white;
      border-width: 0 3px 3px 0;
      transform: scaleX(1.3) rotate(45deg);
      transition: border-color 0.3s ease;

      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 8px;
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid white;
        border-width: 0 3px 3px 0;
        transition: border-color 0.3s ease 0.1s;
      }
    }
  }

  &.-normal {
    .hero_ctn {
      padding: 1rem 0 3rem;
      border-radius: 5px;
      margin: 20px 0;

      @include media-breakpoint-up(lg) {
        margin: 30px 0;
        border-radius: 5px 0 0 5px;
      }
    }
  }

  &.-normal,
  &.-normal_slider {
    min-height: 450px;

    .hero_ctn {
      flex: 0 0 65%;
      align-items: flex-start;

      .buttonsCareer {
        background-color: transparent;
        margin: 35px 0 0 15px;

        .btn {
          &:first-child {
            margin-right: $space-sm;
          }
        }
      }
    }

    .hero_media {
      flex: 1;
    }

    .hero_meta {
      flex: 100%;
      background-color: $secondary;
      color: white;

      .cta {
        padding: $space-sm;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dhsv_search_city {
          width: 40%;

          .btn {
            background-color: $primary;
          }

          .dhsv_loading_indicator {
            //
          }
        }

        > div:last-child {
          display: flex;
          text-transform: uppercase;
          font-weight: 500;
          margin-left: $space-xs;

          p {
            margin: 0;
          }

          a {
            &:hover {
              color: $grey;
            }
          }
        }
      }
    }

    .hero_bubble {
      z-index: 2;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%) rotate(22.5deg);
      width: 180px;
      height: 180px;
      border-radius: 50%;
      padding: 0.5rem;
      box-shadow: 0 0 10px 3px rgba($grey, 0.25);
      background-color: $primary;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 600;
        font-size: $font-size-h4;
      }
    }
  }

  &.-slider {
    height: calc(90vh - 80px);
    min-height: 600px;

    > div {
      flex: 1;
      max-width: 50%;
      height: 100%;

      &.hero_ctn {
        height: calc(100% - 40px);
        margin: 20px 0;
        border-radius: $border-radius 0 0 $border-radius;
        padding-bottom: $space-md;

        @include media-breakpoint-up(xxl) {
          height: calc(100% - 60px);
          margin: 30px 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    &.-normal {
      .hero_meta {
        .cta {
          .dhsv_search_city {
            width: 35%;
          }
        }
      }
    }

    &.-slider {
      .hero_ctn {
        > div {
          padding: 0 30px;
          min-width: unset;
          width: 100%;

          @include media-breakpoint-up(lg) {
            padding: 0 60px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &_ctn {
      > div {
        h1 {
          font-size: $font-size-h2;
        }
      }
    }

    &.-normal {
      .hero_meta {
        .cta {
          .dhsv_search_city {
            width: 50%;
          }

          > div:last-child {
            flex-wrap: wrap;
            justify-content: flex-end;

            p {
              flex: 0 1 auto;

              span {
                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &.-slider {
      min-height: calc(100vh - 80px);

      @include media-breakpoint-up(xxl) {
        min-height: calc(100vh - 70px);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &_ctn {
      &.-normal,
      &.-normal_slider {
        .hero_media {
          &_career {
            img {
              height: 6rem;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &_ctn {
      > div {
        h1,
        p {
          text-align: center;
        }
      }
    }

    &.-normal,
    &.-normal_slider {
      .hero_ctn {
        flex: 100%;

        > div {
          min-width: unset;
          width: 100%;
        }

        .buttonsCareer {
          margin: 2rem auto $space-xs;
        }
      }

      .hero_media {
        flex: 100%;

        &_image {
          height: 200px;
        }

        &_slider {
          height: 200px;
        }

        &_career {
          top: 0;
          left: 50%;
          transform: translate(-50%, -25%);
          flex-direction: row;

          img {
            height: 8rem;

            &:first-child {
              margin-bottom: 0;
              margin-right: $space-xs;
            }
          }
        }
      }

      .hero_meta {
        .cta {
          flex-direction: column;

          .dhsv_search_city {
            width: 90%;
            margin-bottom: $space-sm;
          }

          > div:last-child {
            flex-direction: column;
            justify-content: center;
            text-align: center;
          }
        }
      }

      .hero_bubble {
        left: 15px;
        top: unset;
        bottom: 200px;
        transform: translate(0, 75%) rotate(22.5deg);
        width: 100px;
        height: 100px;
        padding: 0.2rem;

        span {
          font-size: $font-size-index;
        }
      }
    }

    &.-slider {
      height: auto;
      min-height: 0;

      > div {
        flex: 100%;
        max-width: 100%;
        height: auto;
      }

      .hero_ctn {
        padding: $space-sm 0;

        @include media-breakpoint-down(lg) {
          border-radius: 5px !important;
        }

        .m-bottom-sm {
          margin-bottom: 0;
        }

        .m-top-md {
          margin-top: $space-sm;
        }

        .m-top-sm {
          margin-top: $space-sm * 1.5;
        }

        > div {
          p {
            margin: 0;
          }
        }
      }

      .hero_media {
        flex: 100%;

        &_slider {
          height: 200px;
        }
      }

      .hero_scroll {
        bottom: 180px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.-normal {
      .hero_meta {
        .cta {
          .dhsv_search_city {
            width: 100%;
          }
        }
      }
    }

    &.-slider {
      > div {
        .buttonsEdited {
          .btn {
            width: 100%;

            span {
              display: block;
              opacity: 0;
              height: 0;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    &_ctn {
      > div {
        h1 {
          font-size: 1.8rem;
          padding: 0 $space-xs;
        }
      }

      .dhsv_search_city {
        margin-top: $space-md;
      }
    }

    &.-normal {
      .hero_ctn {
        .buttonsCareer {
          flex-direction: column;

          .btn {
            &:first-child {
              margin-right: 0;
            }
          }
        }
      }

      .hero_meta {
        .cta {
          .dhsv_search_city {
            width: 100%;
          }
        }
      }
    }

    &.-slider {
      .hero_ctn {
        height: calc(100vh - 50px);

        > div {
          padding: 0 15px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }

      @keyframes jump {
        0% {
          transform: scaleX(1) translate(-50%, 0);
        }

        45% {
          transform: scaleX(0.9) translate(-50%, 10px);
        }

        55% {
          transform: scaleX(0.9) translate(-50%, 10px);
        }

        100% {
          transform: scaleX(1) translate(-50%, 0);
        }
      }

      .hero_scroll {
        bottom: 270px;
        display: none;

        span {
          width: 14px;
          height: 14px;

          &::after {
            top: 8px;
            left: 8px;
            width: 14px;
            height: 14px;
          }
        }
      }

      &.is-ios {
        .hero_ctn {
          //height: calc(100vh - 50px - 56px);
        }
      }
    }
  }

  &.-half {
    padding: 0 82px;

    @include media-breakpoint-down(xl) {
      padding: 0 15px;
    }

    @include media-breakpoint-down(md) {
      flex-flow: column;
    }

    .hero_ctn {
      width: 50%;
      margin: 4rem 0;
      padding: 5rem 0;
      background: $white-grey;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      @include media-breakpoint-down(lg) {
        margin: 2rem 0;
        padding: 3rem 0;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
      }

      > div {
        min-width: auto;
        width: 100%;
        padding: 0 5rem;
        color: $dark_navy;

        @include media-breakpoint-down(lg) {
          padding: 0 3rem;
        }

        .breadcrumb {
          margin-bottom: 1rem;

          span,
          a {
            color: $newblue;
          }

          a {
            &:hover {
              color: $secondary;
            }
          }
        }

        h1 {
          margin-bottom: 4rem;

          @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
          }
        }

        .hero_buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 -8px;

          @include media-breakpoint-down(md) {
            flex-flow: column;
            margin: 0;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(50% - 16px);
            margin: 8px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-weight: 600;

            @include media-breakpoint-down(md) {
              width: 100%;
              max-width: 250px;
            }

            i {
              display: block;
              margin-left: 6px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .hero_media {
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .hero_media_image {
      max-height: 100vh;
    }
  }

  &.-full {
    position: relative;
    max-width: 1850px;

    .hero_media {
      width: 100%;

      img {
        @include media-breakpoint-down(xl) {
          min-height: calc(100vh - 50px);
        }
      }
    }

    .hero_ctn {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      border-radius: 10px;
      width: calc(50% - 30px);
      transform: translate(70px, -50%);
      padding: 90px;
      background-color: #677f70;

      & > div {
        min-width: 100%;
        width: 100%;
      }

      .dhsv_search_city {
        .material-icons {
          font-size: 28px;
        }
      }

      @include media-breakpoint-down(xl) {
        h1 {
          font-size: 2.75rem;
        }
      }

      @include media-breakpoint-down(lg) {
        width: calc(100% - 60px);
        transform: translate(-50%, 0);
        top: auto;
        left: 50%;
        bottom: 20px;
        padding: 45px 30px;
        //background-color: rgba(13, 26, 34, 0.7);

        h1,
        p {
          padding: 0;
          text-align: left;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 35px 10px;

        h1,
        p {
          padding: 0;
        }
      }
    }
  }
}
