/*

Bootstrap Variablen Ã¼berschreiben

  das Ãberschreiben von Variablen funktioniert mit SCSS etwas anders als mit LESS:

  um den Wert einer Variablen zu Ã¤ndern, kopiert man diese aus _variables-bootstrap.scss in hier rein,
  entfernt am Ende der Zeile das '!default' und passt den Wert an.

*/

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 720px,
  md: 950px,
  lg: 1100px,
  xl: 1300px,
  xxl: 1820px,
);

.social-icons {
  justify-content: space-around;

  a {
    margin: 0 5px 10px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}
