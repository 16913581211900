@import '../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~react-awesome-slider/dist/styles';

.hero {
  .awssld {
    --slider-height-percentage: 100%;

    height: 100%;

    .awssld__wrapper {
      .awssld__container {
        height: 100%;
        padding-bottom: 0;

        .awssld__box {
          .awssld__content {
            background-color: $grey-dark;
            transition: opacity 0.3s ease;

            > div {
              width: 100%;
              height: 100%;

              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
              }
            }
          }
        }

        .awssld__bar {
          opacity: 0;
        }
      }

      .awssld__control {
        z-index: 10;
        position: absolute;
        bottom: 0;
        right: $space-sm;
        display: none;

        div {
          width: 14rem;
          height: 3.5rem;
          cursor: pointer;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 12px 15px 8px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-color: $secondary-shaded;
          color: white;
          font-weight: 500;
          font-size: $font-size-index;
          transition: padding 0.3s ease;

          span {
            display: inline-block;
            white-space: nowrap;
            transition: opacity 0.3s ease-in;
          }

          svg {
            width: 0;
            position: absolute;
            bottom: 0;
            right: 100%;
            transition: width 0.4s ease;

            path {
              fill: $secondary;
            }

            &.mirrored {
              right: unset;
              left: 100%;
              transform: scaleX(-1);
            }
          }

          &:nth-child(1) {
            z-index: 3;
          }

          &:nth-child(2) {
            z-index: 2;
            transform: translateX(-15px);
          }

          &:nth-child(3) {
            z-index: 1;
            transform: translateX(-30px);
          }

          &.active {
            z-index: 4;
            background-color: $secondary;

            svg {
              width: 20px;
            }
          }

          &:not(.active) {
            span {
              opacity: 0;
            }

            &:hover {
              padding-top: 20px;
            }
          }
        }

        &.disabled {
          div {
            &:not(.active) {
              pointer-events: none;
            }
          }
        }

        &[data-active='1'] {
          div {
            &:nth-child(3) {
              background-color: darken($secondary-shaded, 15%);
            }
          }
        }

        &[data-active='2'] {
          div {
            &:nth-child(1) {
              background-color: darken($secondary-shaded, 15%);
            }
          }
        }

        &[data-active='3'] {
          div {
            &:nth-child(1) {
              z-index: 1;
            }

            &:nth-child(2) {
              background-color: darken($secondary-shaded, 15%);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .awssld {
      .awssld__wrapper {
        .awssld__control {
          right: 15px;

          div {
            width: 11rem;
            height: 3rem;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 8px 10px 6px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: $secondary-shaded;
            color: white;
            font-weight: 500;
            font-size: 0.8rem;
            transition: padding 0.3s ease;

            span {
              display: inline-block;
              white-space: nowrap;
              transition: opacity 0.3s ease-in;
            }
          }
        }
      }
    }
  }
}
