.social-icons {
  justify-content: space-around;
}

.social-icons a {
  margin: 0 5px 10px;
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

.hero {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 1800px;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes movingGradient {
  0% {
    background-size: 100%;
    background-position: left center;
    background-repeat: no-repeat;
  }

  33% {
    background-size: 200%;
    background-position: left bottom;
    background-repeat: no-repeat;
  }

  50% {
    background-size: 150%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  66% {
    background-size: 200%;
    background-position: right top;
    background-repeat: no-repeat;
  }

  100% {
    background-size: 100%;
    background-position: left top;
    background-repeat: no-repeat;
  }
}

.hero_ctn {
  position: relative;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #677f70;
  background-repeat: no-repeat;
  animation: movingGradient 30s ease infinite;
}

.hero_ctn>div {
  padding: 0 15px;
  min-width: 680px;
  width: 75%;
  color: #fff;
}

.hero_ctn>div .breadcrumb {
  margin-bottom: 4rem;
  text-transform: uppercase;
}

.hero_ctn>div .breadcrumb span,
.hero_ctn>div .breadcrumb a {
  color: #d4d9dd;
}

.hero_ctn>div .breadcrumb a:hover {
  color: #fff;
}

.hero_ctn>div p {
  font-size: 1.2rem;
}

.hero_ctn>div .buttonsEdited {
  font-size: .9rem;
  font-weight: 500;
}

.hero_ctn>div .buttonsEdited .btn {
  text-transform: uppercase;
}

.hero_ctn>div .buttonsEdited .btn--default {
  color: currentColor;
  border: 1px solid currentColor;
  opacity: .75;
  transition: opacity .2s ease-in;
}

.hero_ctn>div .buttonsEdited .btn--default:hover {
  color: currentColor;
  background-color: rgba(0,0,0,0);
  opacity: 1;
  transition: opacity .3s ease;
}

.hero_ctn>div .buttonsEdited .btn--default.phone {
  opacity: 1;
  transition: color .2s ease-in,border-color .2s ease-in;
}

.hero_ctn>div .buttonsEdited .btn--default.phone:hover {
  color: #e9511d;
  border-color: #e9511d;
}

.hero_ctn>div .buttonsEdited button {
  cursor: auto;
}

.hero_media {
  position: relative;
}

.hero_media_image {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  border-radius: 5px;
  overflow: hidden;
}

.hero_media_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero_media_slider {
  width: 100%;
  height: 100%;
}

.hero_media_slider .awssld__wrapper {
  border-radius: 5px;
}

.hero_media_career {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 9;
}

.hero_media_career img {
  height: 10rem;
}

@media (min-width: 992px) {
  .hero_media_career img {
    height: 8rem;
  }
}

@media (min-width: 1200px) {
  .hero_media_career img {
    height: 10rem;
  }
}

.hero_media_career img:first-child {
  margin-bottom: 1rem;
}

@keyframes jump {
  0% {
    transform: scaleX(1) translate(-50%, 0);
  }

  45% {
    transform: scaleX(0.9) translate(-50%, 20px);
  }

  55% {
    transform: scaleX(0.9) translate(-50%, 20px);
  }

  100% {
    transform: scaleX(1) translate(-50%, 0);
  }
}

.hero_scroll {
  z-index: 10;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  animation: jump 5s ease infinite;
}

@media (min-width: 992px) {
  .hero_scroll {
    display: none;
  }
}

.hero_scroll:hover span {
  border-color: #e9511d;
}

.hero_scroll:hover span::after {
  border-color: #e9511d;
}

.hero_scroll span {
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  transform: scaleX(1.3) rotate(45deg);
  transition: border-color .3s ease;
}

.hero_scroll span::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  transition: border-color .3s ease .1s;
}

.hero.-normal .hero_ctn {
  padding: 1rem 0 3rem;
  border-radius: 5px;
  margin: 20px 0;
}

@media (min-width: 992px) {
  .hero.-normal .hero_ctn {
    margin: 30px 0;
    border-radius: 5px 0 0 5px;
  }
}

.hero.-normal,
.hero.-normal_slider {
  min-height: 450px;
}

.hero.-normal .hero_ctn,
.hero.-normal_slider .hero_ctn {
  flex: 0 0 65%;
  align-items: flex-start;
}

.hero.-normal .hero_ctn .buttonsCareer,
.hero.-normal_slider .hero_ctn .buttonsCareer {
  background-color: rgba(0,0,0,0);
  margin: 35px 0 0 15px;
}

.hero.-normal .hero_ctn .buttonsCareer .btn:first-child,
.hero.-normal_slider .hero_ctn .buttonsCareer .btn:first-child {
  margin-right: 2rem;
}

.hero.-normal .hero_media,
.hero.-normal_slider .hero_media {
  flex: 1;
}

.hero.-normal .hero_meta,
.hero.-normal_slider .hero_meta {
  flex: 100%;
  background-color: #e9511d;
  color: #fff;
}

.hero.-normal .hero_meta .cta,
.hero.-normal_slider .hero_meta .cta {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero.-normal .hero_meta .cta .dhsv_search_city,
.hero.-normal_slider .hero_meta .cta .dhsv_search_city {
  width: 40%;
}

.hero.-normal .hero_meta .cta .dhsv_search_city .btn,
.hero.-normal_slider .hero_meta .cta .dhsv_search_city .btn {
  background-color: #677f70;
}

.hero.-normal .hero_meta .cta>div:last-child,
.hero.-normal_slider .hero_meta .cta>div:last-child {
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 1rem;
}

.hero.-normal .hero_meta .cta>div:last-child p,
.hero.-normal_slider .hero_meta .cta>div:last-child p {
  margin: 0;
}

.hero.-normal .hero_meta .cta>div:last-child a:hover,
.hero.-normal_slider .hero_meta .cta>div:last-child a:hover {
  color: #484848;
}

.hero.-normal .hero_bubble,
.hero.-normal_slider .hero_bubble {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(22.5deg);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  padding: .5rem;
  box-shadow: 0 0 10px 3px rgba(72,72,72,.25);
  background-color: #677f70;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero.-normal .hero_bubble span,
.hero.-normal_slider .hero_bubble span {
  font-weight: 600;
  font-size: 1.125rem;
}

.hero.-slider {
  height: calc(90vh - 80px);
  min-height: 600px;
}

.hero.-slider>div {
  flex: 1;
  max-width: 50%;
  height: 100%;
}

.hero.-slider>div.hero_ctn {
  height: calc(100% - 40px);
  margin: 20px 0;
  border-radius: 5px 0 0 5px;
  padding-bottom: 4rem;
}

@media (min-width: 1600px) {
  .hero.-slider>div.hero_ctn {
    height: calc(100% - 60px);
    margin: 30px 0;
  }
}

@media (max-width: 1599.98px) {
  .hero.-normal .hero_meta .cta .dhsv_search_city {
    width: 35%;
  }

  .hero.-slider .hero_ctn>div {
    padding: 0 30px;
    min-width: unset;
    width: 100%;
  }
}

@media (max-width: 1599.98px)and (min-width: 992px) {
  .hero.-slider .hero_ctn>div {
    padding: 0 60px;
  }
}

@media (max-width: 1199.98px) {
  .hero_ctn>div h1 {
    font-size: 2.75rem;
  }

  .hero.-normal .hero_meta .cta .dhsv_search_city {
    width: 50%;
  }

  .hero.-normal .hero_meta .cta>div:last-child {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .hero.-normal .hero_meta .cta>div:last-child p {
    flex: 0 1 auto;
  }

  .hero.-normal .hero_meta .cta>div:last-child p span:last-child {
    display: none;
  }

  .hero.-slider {
    min-height: calc(100vh - 80px);
  }
}

@media (max-width: 1199.98px)and (min-width: 1600px) {
  .hero.-slider {
    min-height: calc(100vh - 70px);
  }
}

@media (max-width: 767.98px) {
  .hero_ctn.-normal .hero_media_career img,
  .hero_ctn.-normal_slider .hero_media_career img {
    height: 6rem;
  }
}

@media (max-width: 991.98px) {
  .hero_ctn>div h1,
  .hero_ctn>div p {
    text-align: center;
  }

  .hero.-normal .hero_ctn,
  .hero.-normal_slider .hero_ctn {
    flex: 100%;
  }

  .hero.-normal .hero_ctn>div,
  .hero.-normal_slider .hero_ctn>div {
    min-width: unset;
    width: 100%;
  }

  .hero.-normal .hero_ctn .buttonsCareer,
  .hero.-normal_slider .hero_ctn .buttonsCareer {
    margin: 2rem auto 1rem;
  }

  .hero.-normal .hero_media,
  .hero.-normal_slider .hero_media {
    flex: 100%;
  }

  .hero.-normal .hero_media_image,
  .hero.-normal_slider .hero_media_image {
    height: 200px;
  }

  .hero.-normal .hero_media_slider,
  .hero.-normal_slider .hero_media_slider {
    height: 200px;
  }

  .hero.-normal .hero_media_career,
  .hero.-normal_slider .hero_media_career {
    top: 0;
    left: 50%;
    transform: translate(-50%, -25%);
    flex-direction: row;
  }

  .hero.-normal .hero_media_career img,
  .hero.-normal_slider .hero_media_career img {
    height: 8rem;
  }

  .hero.-normal .hero_media_career img:first-child,
  .hero.-normal_slider .hero_media_career img:first-child {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .hero.-normal .hero_meta .cta,
  .hero.-normal_slider .hero_meta .cta {
    flex-direction: column;
  }

  .hero.-normal .hero_meta .cta .dhsv_search_city,
  .hero.-normal_slider .hero_meta .cta .dhsv_search_city {
    width: 90%;
    margin-bottom: 2rem;
  }

  .hero.-normal .hero_meta .cta>div:last-child,
  .hero.-normal_slider .hero_meta .cta>div:last-child {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .hero.-normal .hero_bubble,
  .hero.-normal_slider .hero_bubble {
    left: 15px;
    top: unset;
    bottom: 200px;
    transform: translate(0, 75%) rotate(22.5deg);
    width: 100px;
    height: 100px;
    padding: .2rem;
  }

  .hero.-normal .hero_bubble span,
  .hero.-normal_slider .hero_bubble span {
    font-size: 1rem;
  }

  .hero.-slider {
    height: auto;
    min-height: 0;
  }

  .hero.-slider>div {
    flex: 100%;
    max-width: 100%;
    height: auto;
  }

  .hero.-slider .hero_ctn {
    padding: 2rem 0;
  }
}

@media (max-width: 991.98px)and (max-width: 1199.98px) {
  .hero.-slider .hero_ctn {
    border-radius: 5px !important;
  }
}

@media (max-width: 991.98px) {
  .hero.-slider .hero_ctn .m-bottom-sm {
    margin-bottom: 0;
  }

  .hero.-slider .hero_ctn .m-top-md {
    margin-top: 2rem;
  }

  .hero.-slider .hero_ctn .m-top-sm {
    margin-top: 3rem;
  }

  .hero.-slider .hero_ctn>div p {
    margin: 0;
  }

  .hero.-slider .hero_media {
    flex: 100%;
  }

  .hero.-slider .hero_media_slider {
    height: 200px;
  }

  .hero.-slider .hero_scroll {
    bottom: 180px;
  }
}

@media (max-width: 767.98px) {
  .hero.-normal .hero_meta .cta .dhsv_search_city {
    width: 100%;
  }

  .hero.-slider>div .buttonsEdited .btn {
    width: 100%;
  }

  .hero.-slider>div .buttonsEdited .btn span {
    display: block;
    opacity: 0;
    height: 0;
  }
}

@media (max-width: 575.98px) {
  .hero_ctn>div h1 {
    font-size: 1.8rem;
    padding: 0 1rem;
  }

  .hero_ctn .dhsv_search_city {
    margin-top: 4rem;
  }

  .hero.-normal .hero_ctn .buttonsCareer {
    flex-direction: column;
  }

  .hero.-normal .hero_ctn .buttonsCareer .btn:first-child {
    margin-right: 0;
  }

  .hero.-normal .hero_meta .cta .dhsv_search_city {
    width: 100%;
  }

  .hero.-slider .hero_ctn {
    height: calc(100vh - 50px);
  }

  .hero.-slider .hero_ctn>div {
    padding: 0 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

@keyframes jump {
    0% {
      transform: scaleX(1) translate(-50%, 0);
    }

    45% {
      transform: scaleX(0.9) translate(-50%, 10px);
    }

    55% {
      transform: scaleX(0.9) translate(-50%, 10px);
    }

    100% {
      transform: scaleX(1) translate(-50%, 0);
    }
}

  .hero.-slider .hero_scroll {
    bottom: 270px;
    display: none;
  }

  .hero.-slider .hero_scroll span {
    width: 14px;
    height: 14px;
  }

  .hero.-slider .hero_scroll span::after {
    top: 8px;
    left: 8px;
    width: 14px;
    height: 14px;
  }
}

.hero.-half {
  padding: 0 82px;
}

@media (max-width: 1599.98px) {
  .hero.-half {
    padding: 0 15px;
  }
}

@media (max-width: 991.98px) {
  .hero.-half {
    flex-flow: column;
  }
}

.hero.-half .hero_ctn {
  width: 50%;
  margin: 4rem 0;
  padding: 5rem 0;
  background: #edf4f8;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (max-width: 1199.98px) {
  .hero.-half .hero_ctn {
    margin: 2rem 0;
    padding: 3rem 0;
  }
}

@media (max-width: 991.98px) {
  .hero.-half .hero_ctn {
    width: 100%;
    margin-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}

.hero.-half .hero_ctn>div {
  min-width: auto;
  width: 100%;
  padding: 0 5rem;
  color: #2a4154;
}

@media (max-width: 1199.98px) {
  .hero.-half .hero_ctn>div {
    padding: 0 3rem;
  }
}

.hero.-half .hero_ctn>div .breadcrumb {
  margin-bottom: 1rem;
}

.hero.-half .hero_ctn>div .breadcrumb span,
.hero.-half .hero_ctn>div .breadcrumb a {
  color: #677f70;
}

.hero.-half .hero_ctn>div .breadcrumb a:hover {
  color: #e9511d;
}

.hero.-half .hero_ctn>div h1 {
  margin-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .hero.-half .hero_ctn>div h1 {
    margin-bottom: 2rem;
  }
}

.hero.-half .hero_ctn>div .hero_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -8px;
}

@media (max-width: 991.98px) {
  .hero.-half .hero_ctn>div .hero_buttons {
    flex-flow: column;
    margin: 0;
  }
}

.hero.-half .hero_ctn>div .hero_buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 16px);
  margin: 8px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .hero.-half .hero_ctn>div .hero_buttons a {
    width: 100%;
    max-width: 250px;
  }
}

.hero.-half .hero_ctn>div .hero_buttons a i {
  display: block;
  margin-left: 6px;
  font-weight: 700;
}

.hero.-half .hero_media {
  width: 50%;
}

@media (max-width: 991.98px) {
  .hero.-half .hero_media {
    width: 100%;
  }
}

.hero.-half .hero_media_image {
  max-height: 100vh;
}

.hero.-full {
  position: relative;
  max-width: 1850px;
}

.hero.-full .hero_media {
  width: 100%;
}

@media (max-width: 1599.98px) {
  .hero.-full .hero_media img {
    min-height: calc(100vh - 50px);
  }
}

.hero.-full .hero_ctn {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  width: calc(50% - 30px);
  transform: translate(70px, -50%);
  padding: 90px;
  background-color: #677f70;
}

.hero.-full .hero_ctn>div {
  min-width: 100%;
  width: 100%;
}

.hero.-full .hero_ctn .dhsv_search_city .material-icons {
  font-size: 28px;
}

@media (max-width: 1599.98px) {
  .hero.-full .hero_ctn h1 {
    font-size: 2.75rem;
  }
}

@media (max-width: 1199.98px) {
  .hero.-full .hero_ctn {
    width: calc(100% - 60px);
    transform: translate(-50%, 0);
    top: auto;
    left: 50%;
    bottom: 20px;
    padding: 45px 30px;
  }

  .hero.-full .hero_ctn h1,
  .hero.-full .hero_ctn p {
    padding: 0;
    text-align: left;
  }
}

@media (max-width: 767.98px) {
  .hero.-full .hero_ctn {
    padding: 35px 10px;
  }

  .hero.-full .hero_ctn h1,
  .hero.-full .hero_ctn p {
    padding: 0;
  }
}

